import { getValueFromCurrentUrl } from '../../qaamgo/helper/query-fragment-params';
import { pageNotification } from '../../qaamgo/helper/notify';

import(
    /* webpackMode: "eager" */
    '../qg-main'
).then(function () {
    const remoteUrl = getValueFromCurrentUrl('remote_url');
    const externalUrl = getValueFromCurrentUrl('external_url');

    let fileUrl = null;
    if (remoteUrl) {
        fileUrl = remoteUrl;
    }

    if (externalUrl) {
        fileUrl = externalUrl;
    }

    const $remoteUrlInput = $('.remote-url-input');
    if (fileUrl === null) {
        $remoteUrlInput.attr('disabled', false);
    } else {
        $remoteUrlInput.val(fileUrl).attr('title', fileUrl);
    }

    $('.one-click-convert-btn').removeClass('disabled');

    $(document).on('click', '.one-click-convert-btn', function (e) {
        e.stopPropagation();
        e.preventDefault();

        fileUrl = $remoteUrlInput.val();
        if (!fileUrl) {
            pageNotification.warning('Please add a link to the file you want to convert.');

            return;
        }

        const featureLink = $(this).data('link');

        window.location = `${featureLink}#remote_url=${fileUrl}`;
    });
});
